:root {
  --bgcolor: rgb(10,10,10);
  --color: rgb(250,250,240);
  --trim: rgb(10,10,15);
  --trimlow: rgb(5,5,100);
  --trimlow2: rgb(20,20,25);
  --trimlow3: rgb(20,20,25);
  --none: rgba(0,0,0,0);
}

.dropdown {
  position: relative;
  display: inline-block;
  width:130px;
  border:1px solid rgb(50,50,50);
  margin-bottom: 50px;
  margin-left:10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--bgcolor);
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

}

.dropdown-content a {
  color: var(--color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: var(--trim);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: var(--trimlow2);
}

.dropbtn {
  background-color: var(--trimlow3);
  color: var(--color);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
    width:100%;
    color:skyblue;
}
