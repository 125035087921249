:root {
    --scrollbar-track-color: #f1f1f1;
    --scrollbar-thumb-color: rgba(1,1,5,1);
    --scrollbar-bg-color: rgba(10,10,255,0);
    --scrollbar-height: 400px;
    --placeholdercolor: rgba(50,50,50,1);
    --speaker:rgba(0, 206, 235, 1);
    --speaker2:rgba(135, 206, 235, .5);
    --bubble:#141414;
}

.messages::-webkit-scrollbar {
    width: 12px;
}

.messages::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}

.messages::-webkit-scrollbar-thumb {
    background-color: var(--trimlow3);
    border-radius: 6px;
    border: 3px solid rgba(0,0,0,0);
}

.messages::-webkit-scrollbar-track {
    background: var(--scrollbar-bg-color);
}

.messages {
    display: grid;
    grid-template-rows: 1fr;
    width: 100%;
    max-width: 900px;
    height: calc(100vh - 230px); /* Adjust the height calculation to account for the missing space */
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
}

.response:first-child {
    margin-top: auto;
}

.response {
    width: 90%;
}

.response {
    border-right: 3px solid var(--none);
    padding: 40px 20px 40px 0;
    margin: 10px 0;
}

.chat {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 900px;
    overflow: hidden; /* Prevents scrolling of the chat container */
    position: relative; /* Ensure relative positioning for margin adjustments */
    padding-top: 0; /* Reset padding-top */
}

.input-area {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    border: 1px solid var(--trim);
    padding: 10px 30px;
    border:1px solid var(--speaker2);
    margin-left:0px;
    border-radius: 5px;
    background-color: var(--trimlow2);
}

input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: var(--trimlow2);
    color: var(--color);
    font-size: 16px;
}

.input {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    background-color:var(--bubble);
    border-radius: 10px;
    margin: 0px 0 40px 0px;
    color:#444;
    line-height: 1.3em;
    width:100%;
}

.star_container {
    display: flex;
}

.star_column {
    display: flex;
    align-items: center;
    justify-content: center;
}

.star_left, .star_right {
    max-width: 30px !important;
    padding: 0 20px 0 10px;
    font-size: 25px;
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.star_left {
    margin-left: -20px;
    color: lightgray; /* Default color */
}

.star_left.active {
    color: yellow; /* Active color */
}

.star_center {
    flex-grow: 1;
}

button {
    padding: 10px 20px;
    cursor: pointer;
}

.responseContainer {
    display: flex;
}

.avatarContainer {
    width: 35px;
    display: flex;
    align-items: flex-start;
    padding: 0 5px 0 10px;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.responseContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 1.2em;
    line-height: 1.25;
}
