.languageSelector {
  margin-top: 20px;
}
.languageSelectorLabel{
  font-size: 12px;
  opacity: 0;
}

.dropdown {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
}
