@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.who {
  position: relative;
  width: 100px;
  margin: 0 20px;
  cursor: pointer;
  opacity: 1;
  border-radius: 100px;
}

.bg {
  position: relative;
  height: 100%;
  font-size: 16px;
  margin: auto;
    margin-top: 50px;

      width: 170px;

}

.personaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px; /* Fixed width for container */


}

.nameContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.name {
  text-align: center;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
}



.selectedContainer {
  display: flex;
  align-items: center;
  justify-content: center;



}

.selectedAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.selectedName {
  display: flex;
  align-items: left;

  width: 100%;

}

.selected {
  margin-top: 20px;
  text-align: left;
  font-size: 14px;
  padding: 10px;
  text-align: left !important;
    margin-left: 10px;



  color: rgb(135, 206, 235);
}
.personablurb {
  position: absolute;
  margin: -10px 0 0 0px;
  text-align: left;
  width: 100%;
  max-width: 100px;
  font-size: 12px;
  color: rgb(135, 206, 235);

}
