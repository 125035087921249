:root {
  --bgcolor: rgb(0, 0, 0);
  --color: rgb(250, 250, 240);
  --trim: rgb(10, 10, 15);
  --trimlow: rgb(5, 5, 100);
  --trimlow2: rgb(20, 20, 25);
  --trimlow3: rgb(20, 20, 25);
  --none: rgba(0, 0, 0, 0);
  --colleft:rgba(255,255,255,.05);
  --colorblue:rgba(6,135,252,1);
  --colorbox:rgba(30,30,30,1);
  --blue: rgb(0,71,160);
}

html, body {
  overflow: hidden;
  background-color: black;
  color: var(--color);
  font-size: 18px;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.hide {
  display: none;
}

.logo {
  font-size: 40px;
  text-align: center;
  margin-left: 20px;
  margin-bottom: 50px;
  font-weight: bold;
  color: skyblue;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;


}

.header {
  width: 100%;

  color: #FFFFFF;

  text-align: left;
  padding: 10px 10px;
  font-size: 24px;
  border-bottom: 0px solid #444;
  flex-shrink: 0; /* Prevent header from shrinking */





}


.footer {
  width: 100%;

  color: #FFFFFF;
  color: #1C1C1C;
  text-align: center;
  padding: 10px 0;
  min-height: 10px;
  font-size: 44px;

  flex-shrink: 0; /* Prevent header from shrinking */


  background-color: var(--colorbox);


}

.main {
  display: flex;
  flex-grow: 1; /* Ensures columns fill the remaining vertical space */
  overflow: hidden; /* Prevents scrolling */



}

.column {
  display: flex; /* Allows content inside columns to use flexbox properties */
  height: 100%; /* Ensure columns fill the available height */
}

.column.left, .column.right {
  width: 200px; /* Fixed width for left and right columns */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start of the column */


  overflow-y: auto; /* Allow scrolling within the column if content overflows */
}

.column.left{

    background-color: var(--blue);

    border-radius: 0px 20px 20px 0px;
    overflow: hidden;


}
.column.right{
  width:400px;
  background-color: var(--colleft);
    background-color: var(--colorbox);
    border-radius: 20px 0px 0px 0px;
}

.column.center {
  flex-grow: 1; /* Takes remaining space */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: flex-end;
  border-left: 1px solid var(--trim);
  border-right: 1px solid var(--trim);
  overflow-y: auto; /* Allow scrolling within the column if content overflows */
    border-bottom: 1px solid #1C1C1C;

    border:none;


      background-color: black;
}

.coltitle{
  text-align: center;
  width:100%;
  padding:20px 0;
  color:#333;
  font-size: 20px;
}
.notes{
  width:90%;
  margin:auto;
  margin-top:20px;
  height:90%;

}

.logo{
  position: absolute;
  right:20px;
  bottom:-40px;

  width:100px;

}

.elogo{

  margin:-30px 0 -28px -16px;
  width:200px;
  opacity: 1;

}
/* Responsive adjustments */
@media (max-width: 1300px) {
  .column.left, .column.right {
    width: 150px; /* Narrower columns when under 1300px */
  }
}

@media (max-width: 800px) {
  .column.left, .column.right {
    display: none; /* Hide columns when under 800px */
  }
}
