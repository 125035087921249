@keyframes scaleAndFade {
    0% {
        opacity: .5;

    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: scaleAndFade .5s ease-out;
}

.modalContent {
    background-color: white;
    padding: 20px;
    width: 700px;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color:black;
}

.horizontalRule {
    border: 1px solid gray;
    margin: 20px 0;
}
