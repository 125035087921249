@keyframes scaleAndFade {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.note {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    background-color: #ff7eb9;
    background-color: #f97803;
    background-color: #7afcff;
    background-color: #feff9c;
    cursor: pointer;
    color: black;
    animation: scaleAndFade .5s ease-out;
}

.note p {
    margin: 5px 0;
}

.answer {
    position: relative;
    display: flex;
    align-items: center;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
    position: absolute;
    right: 0;
}
